// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dashboard: getIcon('home'),
  daan: getIcon('daan_gold'),
  upload: getIcon('upload_gold'),
  upload_history: getIcon('upload_history'),
  appointment: getIcon('appointment'),
  sell: getIcon('sell_gold'),
  release_gold: getIcon('release_gold'),
  approval: getIcon('approval'),
  transactions: getIcon('transactions'),
  statements: getIcon('statements'),
  security: getIcon('security'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Dashboard',
    items: [
      {
        id: 'allowed',
        title: 'Home',
        path: PATH_DASHBOARD.analytics,
        icon: ICONS.dashboard,
      },

      {
        id: 'allowed',
        title: 'Daan Gold',
        path: PATH_DASHBOARD.daanGold.root,
        icon: ICONS.daan,
      },

      {
        id: 'allowed',
        title: 'Upload Gold',
        path: PATH_DASHBOARD.upload.root,
        icon: ICONS.upload,
        children: [
          {
            id: 'allowed',
            title: 'Upload Gold History',
            path: PATH_DASHBOARD.upload.history,
            icon: ICONS.upload_history,
          },
          {
            id: 'allowed',
            title: 'Appointment',
            path: PATH_DASHBOARD.upload.appointment,
            icon: ICONS.appointment,
          },
        ],
      },

      {
        id: 'allowed',
        title: 'Sell Gold',
        path: PATH_DASHBOARD.sellGold.root,
        icon: ICONS.sell,
      },

      {
        id: 'allowed',
        title: 'Release Gold',
        path: PATH_DASHBOARD.releaseGold.root,
        icon: ICONS.release_gold,
      },

      {
        id: 'approval',
        title: 'Approval',
        path: PATH_DASHBOARD.approval.root,
        icon: ICONS.approval,
      },

      {
        id: 'allowed',
        title: 'View Transactions',
        path: PATH_DASHBOARD.transactions.root,
        icon: ICONS.transactions,
      },

      // {
      //   id: 'allowed',
      //   title: 'Statements',
      //   path: PATH_DASHBOARD.statements.root,
      //   icon: ICONS.statements,
      // },

      // {
      //   id: 'allowed',
      //   title: 'My Security',
      //   path: PATH_DASHBOARD.mySecurity.root,
      //   icon: ICONS.security,
      // },
    ],
  },
];

export default navConfig;
